import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import AuthProvider from './context/AuthProvider';
import OverlayProvider from './context/OverlayProvider';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import LanguageProvider from './context/LanguageProvider';

ReactDOM.render(
  <React.StrictMode>
    <div>
      <img style={{ "width": "100%", "height": "auto" }} src={process.env.PUBLIC_URL + "/img/work.png"}></img>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
<script src="//code.tidio.co/f5tl0cuylu6gqa9owqmjrscuvgwnbcc5.js" async></script>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();